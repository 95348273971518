// Import partials.
@use "settings" as *;

:root {
	--bg-color: #{$bg-color-light};
	--text-color: #{$text-color-light};
	--bg-post: #{$bg-post-light};

	--gray-1: #f1f4f5;
	--gray-2: #ddd;
	--gray-3: #ccc;

	--gray-4: #707070;
	--gray-5: #444;
	--gray-6: #222;

	--link-color: #{$link-color-light};
	--visited-link-color: #{$visited-link-color-light};

	--bg-site-header: #212123;
}

@include dark-mode {
	--bg-color: #{$bg-color-dark};
	--text-color: #{$text-color-dark};
	--bg-post: #{$bg-post-dark};

	--gray-1: #1a1a1c;
	--gray-2: #2a2a2c;
	--gray-3: #3a3a3c;

	--gray-4: #88898b;
	--gray-5: #bbbcbe;
	--gray-6: #dddddf;

	--link-color: #{$link-color-dark};
	--visited-link-color: #{$visited-link-color-dark};

	--bg-site-header: #101013;
}
