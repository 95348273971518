@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI", sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$base-font-size: 18px;
$base-font-weight: 400;
$base-line-height: 1.8;

$header-font-family: "Montserrat", sans-serif;

$spacing-unit: 32px;

$brand-color: #3f1edf;
$link-color-light: #2010ec;
$visited-link-color-light: #7117b4;
$link-color-dark: #4d94f1;
$visited-link-color-dark: #9f3ee7;

$bg-color-light: #f1f4f5;
$bg-color-dark: #000;
$text-color-light: #111;
$text-color-dark: #d0d0d0;
$bg-post-light: #fff;
$bg-post-dark: #121214;

$header-bg-color1: #8a2387;
$header-bg-color2: #e94057;
$header-bg-color3: #f27121;
$header-bg-color6: adjust-hue(#8a2387, 260);
$header-bg-color5: adjust-hue(#e94057, 260);
$header-bg-color4: adjust-hue(#f27121, 260);

$post-width: 950px;
$toc-width: 350px;

$dark-mode-transition-time: 0.3s;
$dark-mode-transition-func: ease-in-out;

@mixin media-larger-than($size) {
	@media screen and (min-width: $size) {
		@content;
	}
}

@mixin media-smaller-than($size) {
	@media screen and (max-width: $size) {
		@content;
	}
}

@mixin media-between($minSize, $maxSize) {
	@media screen and (min-width: $minSize) and (max-width: $maxSize) {
		@content;
	}
}

@mixin dark-mode() {
	#{if(&, '[data-theme="dark"] &', '[data-theme="dark"]')} {
		@content;
	}
}

@mixin no-js() {
	#{if(&, 'html.no-js &', 'html.no-js')} {
		@content;
	}
}

@mixin js() {
	#{if(&, 'html.js &', 'html.js')} {
		@content;
	}
}

@mixin relative-font-size($ratio) {
	font-size: $base-font-size * $ratio;
}

@mixin no-select {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

@mixin center-things($direction: row, $wrap: wrap) {
	display: flex;
	flex-flow: $direction $wrap;
	justify-content: center;
}

@mixin clip-path($polygon) {
	-webkit-clip-path: $polygon;
	clip-path: $polygon;
}
