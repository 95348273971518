@use "../settings" as *;
@use "../css-variables";
@use "../selection-and-scrollbar";
@use "sass:math";
$breakpoint-two-col: 900px;
@mixin gradient-text($colors...) {
	background: linear-gradient(to right, $colors);
	background-size: 400% 400%;
	background-position: 0% 50%;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

$bg-intro-dark: #141415;
$bg-main-dark: #19191A;

:root {
	color-scheme: light dark;
	--bg-intro: #{$bg-intro-dark};
	--bg-hover-view: #{$bg-intro-dark};
	--bg-main: #{$bg-post-light};
	--section-padding: #{$spacing-unit * 3};
	--intro-section-width: 50%;
	@include media-larger-than(1600px) {
		--section-padding: #{$spacing-unit * 4};
	}
	@include media-between($breakpoint-two-col, 1200px) {
		--section-padding: #{$spacing-unit * 2};
	}
	@include media-between(500px, 650px) {
		--section-padding: #{$spacing-unit * 2};
	}
	@include media-smaller-than(500px) {
		--section-padding: #{$spacing-unit};
	}
}

@include media-smaller-than($breakpoint-two-col) {
	.section.section-intro {
		padding-top: $spacing-unit * 2;
		padding-bottom: $spacing-unit * 2;
	}
}

@include dark-mode {
	// --bg-intro: #{$bg-intro-dark};
	--bg-hover-view: #2d2d2f;
	--bg-main: #{$bg-main-dark};
}

// RESET
* {
	margin: 0;
	padding: 0;
}

*,
::before,
::after {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

body {
	background: var(--bg-intro);
	color: $text-color-dark;
	font: 20px/1.5 "Open Sans";
	transition: 0.3s ease-in-out;
}

// ANIMATION KEYFRAMES
@keyframes blinking-cursor {
	0% {
		border-color: #dadada;
	}
	49% {
		border-color: #dadada;
	}
	50% {
		border-color: transparent;
	}
	100% {
		border-color: transparent;
	}
}

@keyframes rotateBackgroundImage {
	from {
		background-position: 100% 50%;
	}
	to {
		background-position: 0% 50%;
	}
}

@keyframes fade-in-slide {
	from {
		opacity: 0;
		transform: translateX(-16px);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

// STYLES
.home-wrapper {
	width: 100%;
}

.section {
	padding: var(--section-padding);
}

@include media-larger-than($breakpoint-two-col) {
	.section-intro {
		position: fixed;
		width: var(--intro-section-width);
		left: 0;
		height: 100%;
		max-height: 100vh;
		overflow-y: auto;
	}
	.section-main {
		position: absolute;
		left: var(--intro-section-width);
		width: calc(100% - var(--intro-section-width));
		min-height: 100vh;
	}
}

// SECTION INTRO
.section-intro {
	@include center-things($direction: column, $wrap: nowrap);
	justify-content: space-between;
	// min-height: 600px;
	padding-bottom: calc(var(--section-padding) - #{math.div($spacing-unit, 2)});
	.hello {
		font: 5rem "Montserrat", "Open Sans", sans-serif;
		text-transform: uppercase;
		letter-spacing: 2px;
		font-weight: bold;
		text-align: center;
		margin-top: -18px;
		@include gradient-text( $header-bg-color4, $header-bg-color5, $header-bg-color6, $header-bg-color1, $header-bg-color2, $header-bg-color3);
		animation: rotateBackgroundImage 2s ease-out;
		@include media-larger-than(1000px) {
			font-size: 6rem;
		}
	}
	// >* {
	//     display: none !important;
	// }
}

.contact-icon-list {
	@include center-things();
}

.contact-icon {
	@include center-things();
	position: relative;
	margin: math.div($spacing-unit, 2) $spacing-unit;
	$icon-color-1: #e94057;
	$icon-color-2: #f27121;
	a {
		@include media-smaller-than(1280px) {
			margin: 0 math.div($spacing-unit, 2);
		}
		.svg-icon {
			width: 48px;
			height: 48px;
			background: rgba(0.07,0.07,0.08,0.2);
			border-radius: 50%;
			backdrop-filter: blur(8px);
		}
		stop {
			transition: 0.2s ease-out;
			stop-color: #eee !important;
		}
		&:hover stop {
			&:first-child {
				stop-color: $icon-color-1 !important;
			}
			&:last-child {
				stop-color: $icon-color-2 !important;
			}
		}
	}
	span {
		@include no-select;
		position: absolute;
		top: -36px;
		font-weight: bold;
		@include gradient-text(transparent, transparent, $icon-color-1, $icon-color-2);
		transition: 0.3s ease-out;
		@include media-smaller-than($breakpoint-two-col) {
			display: none;
		}

		&::before {
			$padding: 12px;
			content: '';
			height: 100%;
			width: calc(100% + #{$padding * 2});
			left: -$padding;
			background: #14141599;
			backdrop-filter: blur(10px);
			position: absolute;
			z-index: -1;
			display: block;
			border-radius: 200px;
			opacity: 0;
			transition: 0.3s ease-out;
		}
	}
	a:hover+span, a:hover+span::before {
		background-position: 100% 50%;
		opacity: 1;
	}
}

// SECTION MAIN
.section-main {
	background: var(--bg-main);
	color: var(--text-color);
	transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
	.mini-header {
		font-size: 2rem;
		color: var(--text-color);
		text-decoration: none;
		@include gradient-text(var(--text-color), var(--text-color), $header-bg-color1, $header-bg-color2);
		transition: 0.3s ease-out;
		&::before {
			content: "./";
		}
		&:hover,
		&:focus,
		&:active {
			background-position: 100% 50%;
		}
	}
	a:not(.mini-header) {
		position: relative;
		text-decoration: none;
		@include gradient-text(var(--link-color), var(--link-color), #160ba4, #6155f3);
		$transition-time: 0.3s;
		transition: $transition-time ease-out;
		@include dark-mode {
			@include gradient-text(var(--link-color), var(--link-color), lighten(#160ba4, 20%), lighten(#6155f3, 20%));
		}
		&:hover,
		&:focus,
		&:active {
			background-position: 100% 50%;
		}
		&::before {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 100%;
			width: 0%;
			height: 1.5px;
			background: linear-gradient(to right, #160ba4, lighten(#6155f3, 20%));
			opacity: 0;
			transition: $transition-time ease-out;
			@include dark-mode {
				background: linear-gradient(to right, lighten(#160ba4, 20%), lighten(#6155f3, 20%));
			}
		}
		&:hover {
			text-decoration: none;
			&::before {
				left: 0;
				opacity: 1;
				width: 100%;
			}
		}
	}
	li {
		line-height: 2rem;
		padding-bottom: 12px;
	}
	.mini-content {
		margin-top: $spacing-unit;
		margin-bottom: var(--section-padding);
		line-height: 2.5rem;
		ul {
			list-style-type: none;
		}
	}
	.mini:last-of-type .mini-content {
		margin-bottom: 0;
	}
}

.mini-about {
	details {
		margin-top: math.div($spacing-unit, 2);
	}
	summary {
		cursor: pointer;
	}
	.details-inner {
		margin-left: $spacing-unit;
		margin-top: math.div($spacing-unit, 2);
	}
	details[open] {
		li {
			opacity: 0;
			animation: 0.5s fade-in-slide ease-out;
			animation-fill-mode: forwards;
			@for $n from 1 through 16 {
				&:nth-of-type(#{$n}) {
					animation-delay: #{math.div($n, 10)}s;
				}
			}
		}
	}
	.classes li a {
		margin-right: 4px;
	}
	.jobs .details-inner {
		margin-bottom: math.div($spacing-unit, 2);
	}
	
	.emoji {
		height: 1em;
		width: 1.75em;
		vertical-align: -0.1em;
		
		margin-left: -1.5em;
		padding-right: 0.75em;
		opacity: 0;
		transform: translateX(8px) scale(1.5);
		transition: 0.15s ease-out;
	}
	li:hover .emoji {
		opacity: 1;
		transform: scale(2);
	}

	.about-job {
		font-weight: bold;
		transition: 0.6s ease-out;
		@mixin bg-about-job($hue1, $hue2) {
			$hue3: saturate(darken($hue2, 5%), 40%);
			$hue4: saturate(darken($hue1, 10%), 50%);
			@include gradient-text($hue1, $hue2, $hue3, $hue4);
			&::before {
				background: linear-gradient(to right, $hue3, $hue4);
			}
			@include dark-mode {
				@include gradient-text(lighten($hue1, 15%), $hue2, $hue3, lighten($hue2, 30%));
				&:hover {
					background-position: 100% 50%;
				}
				&::before {
					background: linear-gradient(to right, $hue3, lighten($hue2, 30%));
				}
			}
		}
		&.job-benchling {
			@include bg-about-job(#1f3ce0, #157fdb);
		}
		&.job-uw {
			@include bg-about-job(#7938e2, #7733e4);
		}
		&.job-amazon {
			@include bg-about-job(#dc570a, #e08700);
		}
		&.job-apple {
			@include bg-about-job(#334, #667);
		}
		&.job-tmobile {
			@include bg-about-job(#6b006b, #ee008c);
		}
		&.job-bloomz {
			@include bg-about-job(#4948ec, #48a6ec);
		}
		&.job-spartabots {
			@include bg-about-job(#228a3b, #2cb24d);
		}
		&:hover {
			background-position: 100% 50%;
		}
	}
}

.mini-blog {
	time {
		padding-left: 6px;
		color: var(--gray-4);
		@include media-between($breakpoint-two-col, 950px) {
			display: block;
			padding-left: 12px;
		}
		@include media-smaller-than(450px) {
			display: block;
			padding-left: 12px;
		}
		&::before {
			content: "// ";
		}
	}
}

.dark-mode-icon {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	@include no-js {
		display: none;
	}
}

.dark-mode-svg {
	$size: 24px;
	$transition-time: 0.3s ease-in-out;
	$show-scale: 1.1;
	$hide-scale: 0.7;
	$hide-translate: 20px;
	transform: translateY(4px);
	width: $size;
	height: $size;
	display: inline;
	.icon-moon,
	.icon-sun,
	path {
		transition: $transition-time;
	}
	path {
		fill: var(--bg-main);
	}
	&:hover {
		.icon-sun path {
			transform: scale(1);
			fill: var(--gray-2);
		}
		@include dark-mode {
			.icon-moon path {
				transform: scale(1);
				fill: var(--gray-2);
			}
		}
	}
	.icon-moon {
		opacity: 0;
		path {
			transform: scale($hide-scale) translateX(-$hide-translate);
			transform-origin: 64px 64px;
		}
		@include dark-mode {
			opacity: 1;
			path {
				transform: scale($show-scale);
			}
		}
	}
	.icon-sun {
		opacity: 1;
		path {
			transform: scale($show-scale);
			transform-origin: 192px 192px;
		}
		@include dark-mode {
			opacity: 0;
			path {
				transform: scale($hide-scale) translateX($hide-translate);
			}
		}
	}
}

abbr {
	position: relative;
	text-decoration: none;
	border-bottom: 2px dotted var(--gray-4);
	// hide abbr underline on mobile devices (devices that cannot hover)
	@media (hover: none) {
		text-decoration: none;
	}
	&::after {
		$padding: 8px;
		$triangle: 6px;
		$fromtop: calc(100% - #{$triangle});
		content: attr(data-title);
		position: absolute;
		bottom: 100%;
		left: 50%;
		z-index: 3;
		transform: translate(-50%, 12px);
		text-align: center;
		line-height: 1.25;
		white-space: nowrap;
		display: block;
		padding: $padding;
		background: var(--gray-6);
		color: var(--gray-1);
		font-size: 70%;
		clip-path: polygon(0% 0%, 100% 0%, 100% $fromtop, calc(50% + #{$triangle}) $fromtop, 50% 100%, calc(50% - #{$triangle}) $fromtop, 0% $fromtop);
		padding-bottom: $padding + $triangle;
		opacity: 0;
		visibility: hidden;
		pointer-events: none; // prevent thrashing
		transition: 0.2s ease-out;
		@include no-js {
			display: none;
		}
	}
	&:hover {
		&::after {
			opacity: 1;
			visibility: visible;
			transform: translate(-50%, 0);
		}
	}
}

// HOVER VIEW
.hover-view {
	$triangle-width: 16px;
	$outer-color: var(--bg-hover-view);
	$padding: 16px;
	@include media-smaller-than($breakpoint-two-col) {
		display: none;
	}
	@include no-js {
		display: none;
	}
	div {
		width: 600px + $padding;
		height: 300px + $padding;
		position: absolute;
		pointer-events: none;
		z-index: 2;
		border-radius: 2px;
		background: $outer-color;
		transform: translateX(-$spacing-unit);
		@include center-things();
		align-items: center;
		opacity: 0;
		transition: opacity 0.25s ease-out;
		&.show {
			opacity: 1;
		}
		&::after {
			content: "";
			position: absolute;
			right: -$triangle-width + 1px;
			width: 0;
			height: 0;
			border-top: $triangle-width * 0.8 solid transparent;
			border-bottom: $triangle-width * 0.8 solid transparent;
			border-left: $triangle-width solid $outer-color;
		}
		img {
			width: 600px;
			height: 300px;
		}
	}
	.hover-view-blog {
		width: 80px + $padding;
		height: 80px + $padding;
		img {
			width: 80px;
			height: 80px;
		}
	}
}

canvas.webgl {
	position: fixed;
	z-index: -1;
	pointer-events: none;
	width: 50%;
	height: 100%;
	@include media-smaller-than($breakpoint-two-col) {
		display: none;
	}
}

canvas.intro-canvas {
	width: 100%;
	height: 100%;
	@include media-smaller-than($breakpoint-two-col) {
		display: none;
	}
}

.wave {
	display: inline-block;
	animation: 3s linear 2s wave;
}

@keyframes wave {
	0% {
		transform: rotate(0deg);
	}
	5% {
		transform: rotate(8deg);
	}
	10% {
		transform: rotate(-4deg);
	}
	15% {
		transform: rotate(8deg);
	}
	20% {
		transform: rotate(-2deg);
	}
	25% {
		transform: rotate(4deg);
	}
	30% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.mini-slider {
	.mini-content {
		text-align: center;
	}
	p, ul {
		text-align: left;
	}
	ul {
		margin-top: 16px;
	}
	li:last-of-type {
		padding-bottom: 0;
	}
	.percent {
		font-family: monospace;
	}
	.slider-content {
		display: none;
		opacity: 0;
		transition: 0.5s ease-out;

		&.show {
			display: block;
		}
		&.show-animate {
			opacity: 1;
			margin-top: -$spacing-unit;
		}

		span.control {
			$size: 14px;
			display: inline-block;
			width: $size;
			height: $size;
			vertical-align: middle;
			border-radius: 50%;
			background: var(--gray-4);
		}
	}
	.show-slider-content {
		font: inherit;
		color: #eee;
		border: 0;
		padding: #{math.div($spacing-unit, 4)} $spacing-unit;
		border-radius: 100px;
		cursor: pointer;
		transition: 0.5s ease-out;
		
		$bg: lighten($bg-main-dark, 15%);
		background: linear-gradient(to right, $bg, $bg, $bg, $header-bg-color4, $header-bg-color5, $header-bg-color6);
		background-size: 400% 400%;
		background-position: 0% 50%;
		
		&:hover, &:active, &:focus {
			background-position: 100% 50%;
		}
		&.hide {
			transform: translateY(-32px);
			opacity: 0;
			pointer-events: none;
		}
	}
	@include media-smaller-than($breakpoint-two-col) {
		display: none;
	}
}

.mini-slider {
	$r: #d41;
	$g: #2b4;
	$b: #16d;
	.r {
		fill: $r;
		stroke: $r;
		color: $r;
	}
	.g {
		fill: $g;
		stroke: $g;
		color: $g;
	}
	.b {
		fill: $b;
		stroke: $b;
		color: $b;
	}
}

.slider {
	width: 370px;
	max-width: 100%;
	stroke-linejoin: round;
	stroke-linecap: round;

	$linewidth: 10;
  
	polygon {
	  fill: transparent;
	  cursor: pointer;
	  transition: 0.2s ease-out;
	  stroke: var(--gray-2);
	  stroke-width: $linewidth;
	  &:hover {
		fill: var(--gray-2);
	  }
	}
	.barycentric {
		opacity: 0;
		transition: 0.3s ease-out;
	}
	polygon:hover {
		& ~ .barycentric {
			opacity: 0.25;
		}
		& ~ .texts {
			opacity: 1;
		}
	}
	line {
		stroke-width: $linewidth;
		pointer-events: none;
	}
	.outer line {
		stroke-width: $linewidth;
	}
	circle {
		transition: fill 0.2s ease-out, r 0.2s ease-out;
	}
	.control {
		fill: var(--gray-4);
		pointer-events: none;

		&.highlight {
			fill: var(--gray-6);
		}
	}
	
	.corner {
		pointer-events: none;
		opacity: 0;
	}
  
	.texts text {
		font: 30px "Open Sans", sans-serif;
		stroke-width: 0;
		@include no-select;
	}
	.texts {
		:nth-child(1) text {
			text-anchor: end;
			transform: rotate(-60deg);
		}
		:nth-child(2) text {
			text-anchor: end;
			transform: rotate(60deg);
		}
		:nth-child(3) text {
			transform: rotate(0deg);
		}
	}
}
  