@use "settings" as *;

::selection {
	background: #3bf;
	color: #000;
	text-shadow: none;
	background-clip: unset;
	-webkit-background-clip: unset;
	-webkit-text-fill-color: black;
}

// Light theme scrollbar

::-webkit-scrollbar {
	width: 12px;
	background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
	background: #aaa;
	border: 2px solid var(--bg-color);
	border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
	background: #888;
}

::-webkit-scrollbar-thumb:active {
	background: #666;
}

:root[data-theme=dark] {
	scrollbar-color: #aaa var(--bg-color);
}